.polaris-spinner {
  display: inline-block;
  width: 4.4rem;
  height: 4.4rem;
  animation: loading .5s linear infinite;
  color: transparent;

  &.polaris-spinner--sm {
    width: 2.2rem;
    height: 2.2rem;
  }

  path {
    fill: $teal;
  }

  &.polaris-spinner--white {
    path {
      fill: $white;
    }
  }

  &.polaris-spinner--ink {
    path {
      fill: $gray-500;
    }
  }
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}