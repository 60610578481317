// Pagination

@mixin pagination-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  .btn {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
  }

  .page-item {
    &:first-child {
      .btn {
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .btn {
        @include border-right-radius($border-radius);
      }
    }
  }
}
