// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

@if $enable-grid-classes {
  .container {
    @include make-container();
    // @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-grid-classes {
  .container-fluid {
    @include make-container();
    width: 100%;
    max-width: 100%;
  }
}

// Left container
//
// Set the container width, and override it for fixed navbars in media queries, 
// but be left aligned

@if $enable-grid-classes {
  .container-left {
    @include make-container();
    // @include make-container-max-widths();
    margin-left: 1rem;
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-grid-classes {
  .row,
  .col-grid {
    @include make-row();
  }

  .col-grid {
    > .col:not(.card-annotation),
    > [class*="col-"]:not(.card-annotation) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  // Remove the negative margin from default .row, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col:not(.card-annotation),
    > [class*="col-"]:not(.card-annotation) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .no-margins {
    margin-top: 0;
    margin-bottom: 0;
  }

  [class*="col-"].card + [class*="col-"].card {
    margin-top: 0;
  }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-grid-classes {
  @include make-grid-columns();
}

// Flex-align helpers
//
// Align the content of a grid column

.col-align-self-top {
  align-self: flex-start;
}

.col-align-self-bottom {
  align-self: flex-end;
}

.col-align-self-center {
  align-self: center;
}
