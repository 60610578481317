.bulk-edit {
  background-color: $white;
}

.bulk-edit__row-form {
  border-bottom: solid 1px $gray-300;
  z-index: 1;

  &:first-child {
    border-top: solid 1px $gray-300;
  }
}

.bulk-edit__row {
  display: flex;
  z-index: 1;
}

.bulk-edit__row--header {
  border-top: solid 1px $gray-300;
  border-bottom: solid 1px $gray-300;

  .bulk-edit__cell {
    border-right: 1px solid $gray-300;
    border-bottom: solid 1px $gray-300;
    color: $gray-600;
  }
}

.bulk-edit__cell {
  // display: table-cell;
  flex-basis: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-top: -1px;
  margin-bottom: -1px;
  border-top: solid 1px $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: solid 1px $gray-300;
  background-color: $white;
  vertical-align: top;
  font-size: 1.4rem;
  z-index: 1;

  &:focus-within {
    z-index: 10;
  }

  &.bulk-edit__cell--string {
    flex-basis: 300px;
    max-width: 300px;
    min-width: 300px;
  }
  &.bulk-edit__cell--button {
    flex-basis: 140px;
    max-width: 140px;
    min-width: 140px;

    .btn {
      width: 100%;
      height: 100%;
    }
  }
  &.bulk-edit__cell--number {
    flex-basis: 140px;
    max-width: 140px;
    min-width: 140px;
    text-align: right;

    .form-control {
      text-align: right;
    }
  }

  .bulk-edit__row:not(.bulk-edit__row--header):hover & {
    background-color: color(indigo, lighter);
  }

  .form-control {
    height: 3.4rem;
    margin: 0;
    border-color: rgba(255,255,255,0);
    border-radius: 0;
    font-size: 1.4rem;

    &:focus,
    .bulk-edit__row:hover &:focus {
      border-color: $indigo;
      // z-index: 10;
    }

    .bulk-edit__row:hover & {
      background-color: color(indigo, lighter);
      border-color: $gray-200;
    }
  }

  .polaris-check {
    margin: 0;
    padding: 0.6rem 1rem;
    font-size: 1.4rem;
  }
}

.bulk-edit__cell--title {
  padding: 0.6rem 1rem;
  background-color: $gray-200;
  border-right: 1px solid $gray-300;
  border-bottom: solid 1px $gray-300;
}