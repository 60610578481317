// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $active-background: $border, $active-border: darken($border, 5%)) {
  fill: general-color-yiq($background);
  @include color-yiq($background);
  background-color: $background;
  @include gradient-y(lighten($background, 2%), darken($background, 2%));
  border-color: $border;
  // box-shadow: 0 0 0 0 transparent,inset 0 1px 1px 0 lighten($background, 5%),inset 0 1px 4px 0 rgba($gray-900,0.2);

  &:hover, &:visited:hover {
    @include color-yiq($active-background);
    // background-color: $active-background;
    @include gradient-y($background, darken($background, 5%));
    border-color: darken($background, 10%);
    @include color-yiq($background);
  }

  &:focus,
  &.focus {
    border-color: lighten($border, 2%);
    box-shadow: inset 0 1px 0 0 lighten($background, 5%), 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 1px lighten($border, 2%);
    @include color-yiq($background);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include color-yiq($background);
    background-color: lighten($background, 25%);
    background-image: none;
    border-color: lighten($background, 20%);
    box-shadow: none;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      @include color-yiq($background);
      background-color: lighten($background, 25%);
      background-image: none;
      border-color: lighten($background, 20%);
      box-shadow: none;
    }
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    @include gradient-y($active-background, $active-background);
    border-color: $active-border;
    box-shadow: inset 0 0 0 0 rgba(0,0,0,0), 0 1px 0 0 rgba(22,29,37,.05), 0 0 0 1px $active-border;
    box-shadow: inset 0 0 0 0 transparent, 0 1px 0 0 rgba(22,29,37,.05), 0 0 1px 0 $active-border;
    @include color-yiq($background);
  }
}

@mixin button-outline-variant($color, $border-color: $color) {
  color: darken($color, 10%);
  background-color: transparent;
  background-image: none;
  border-color: rgba($border-color, 0.4);
  fill: general-color-yiq($color, rgba($color, 0.4), $color);

  @include hover {
    color: darken($color, 10%);
    background-color: rgba($border-color, 0.05);
    background-image: none;
    border-color: rgba($border-color, 0.4);
  }

  &:focus,
  &.focus {
    border-color: rgba($border-color, 0.8);
    box-shadow: 0 0 0 1px rgba($color, 0.8);
  }

  &.disabled,
  &:disabled {
    color: rgba($color, 0.25);
    background-color: transparent;
    background-image: none;
    border-color: rgba($border-color, 0.25);
    fill: $color;
    box-shadow: none;
    
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: rgba($color, 0.25);
      background-color: transparent;
      background-image: none;
      border-color: rgba($border-color, 0.25);
      fill: $color;
      box-shadow: none;
    }
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    background-color: rgba($color, 0.1);
    box-shadow: none;
  }
}

// Button sizes
@mixin button-size($min-height, $padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  min-height: $min-height;
  min-width: $min-height;
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  @include border-radius($border-radius);

  @include media-breakpoint-up(md) {
    font-size: $font-size - 0.1;
    line-height: $line-height - 0.1;
  }
}
