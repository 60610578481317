// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-flex;
  align-items: center;
  padding: $badge-padding-y $badge-padding-x;
  border: 2px solid $white;
  background-color: $gray-300;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 2rem;
  color: $badge-color;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2rem;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

.badge--success {
  background-color: color(green,light);
  color: color(green,text);
}
.badge--info {
  background-color: color(blue,light);
  color: color(blue,text);
}
.badge--attention {
  background-color: color(yellow,light);
  color: color(yellow,text);
}
.badge--warning {
  background-color: color(orange,light);
  color: color(orange,text);
}

.badge__status {
  height: 1rem;
  width: 1rem;
  margin: 0 .4rem 0 -.5rem;
  border: .2rem solid currentColor;
  border-radius: 50%;
  overflow: hidden;
  color: inherit;
  font-size: 1px;
  text-indent: -9999rem;
}

.badge__status--incomplete {
  border: .2rem solid currentColor;
}
.badge__status--partial {
  background: linear-gradient(0deg,currentColor,currentColor 50%,transparent 0,transparent);
}
.badge__status--complete {
  background: currentColor;
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
