.banner {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  padding: 1.6rem;
  border-radius: 0 0 3px 3px;
  background-color: $gray-200;
  box-shadow: inset 0 3px 0 0 $gray-600, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  transition: box-shadow .2s cubic-bezier(.64,0,.35,1);
  transition-delay: .1s;

  &:focus {
    outline: none;
    box-shadow: inset 0 3px 0 0 color(ink, lighter), inset 0 0 0 3px color(ink, lighter), 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  }

  p, li {
    margin: 0;
    font-size: 1.5rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.4rem;
    }
  }

  .btn {
    margin-top: 1.5rem;
  }

  .col-grid [class*="col-"] &:first-child {
    margin-top: 0;
  }

  .col-grid [class*="col-"] &:last-child {
    margin-bottom: 0;
  }
}

.banner--success {
  background-color: color(green, lighter);
  box-shadow: inset 0 3px 0 0 color(green, base), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

  .polaris-icon__has-backdrop {
    fill: color(green, dark);
  
    &:after {
      background-color: color(green, light);
    }
  }

  &:focus {
    box-shadow: inset 0 3px 0 0 color(green, base), inset 0 0 0 3px color(green, base), 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  }
}

.banner--warning {
  background-color: color(yellow, lighter);
  box-shadow: inset 0 3px 0 0 color(yellow, base), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

  .polaris-icon__has-backdrop {
    fill: color(yellow, dark);
  
    &:after {
      background-color: color(yellow, light);
    }
  }

  &:focus {
    box-shadow: inset 0 3px 0 0 color(yellow, base), inset 0 0 0 3px color(yellow, base), 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  }
}

.banner--critical {
  background-color: color(red, lighter);
  box-shadow: inset 0 3px 0 0 color(red, base), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

  .polaris-icon__has-backdrop {
    fill: color(red, dark);
  
    &:after {
      background-color: color(red, light);
    }
  }

  &:focus {
    box-shadow: inset 0 3px 0 0 color(red, base), inset 0 0 0 3px color(red, base), 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  }
}

.banner--info {
  background-color: color(teal, lighter);
  box-shadow: inset 0 3px 0 0 color(teal, base), inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

  .polaris-icon__has-backdrop {
    fill: color(teal, dark);
  
    &:after {
      background-color: color(teal, light);
    }
  }

  &:focus {
    box-shadow: inset 0 3px 0 0 color(teal, base), inset 0 0 0 3px color(teal, base), 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
  }
}

.banner__ribbon {
  flex: 0 0 3.2rem;
  margin-right: 1.6rem;
}